<template>
  <!-- eslint-disable max-len -->
  <svg
    id="svg589"
    width="132.7mm"
    height="297mm"
    viewBox="0 0 132.7 297"
    version="1.1"
    xml:space="preserve"
    sodipodi:docname="human_outline_back_rapid_v4.svg"
    inkscape:version="1.3.2 (091e20e, 2023-11-25)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <sodipodi:namedview
      id="namedview591"
      pagecolor="#ffffff"
      bordercolor="#000000"
      borderopacity="0.25"
      inkscape:showpageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="0"
      inkscape:deskcolor="#d1d1d1"
      inkscape:document-units="mm"
      showgrid="false"
      inkscape:zoom="0.54680687"
      inkscape:cx="-4.5719982"
      inkscape:cy="471.83021"
      inkscape:window-width="1304"
      inkscape:window-height="895"
      inkscape:window-x="91"
      inkscape:window-y="25"
      inkscape:window-maximized="0"
      inkscape:current-layer="layer1"
    />
    <defs id="defs586" />
    <g
      id="layer2"
      inkscape:label="Layer 2"
      inkscape:groupmode="layer"
      style="display: inline"
      transform="matrix(1.0432397,0,0,1.0124749,7.1959566,8.640752)"
    >
      <path
        id="23"
        :class="affected_regions[0] ? 'affected-region' : 'non-affected-region'"      
        d="m 55.263849,1.6949593 c 2.497329,-0.74162396 4.540816,0.03686 6.394437,1.422807 1.63945,1.225815 3.406723,2.664342 4.923925,5.142906 0.359454,0.58722 2.840972,4.0655737 3.014284,5.7107987 0.133052,1.263046 -1.269118,1.462915 -1.961107,2.660595 -0.480005,0.830783 -0.624216,1.91758 -0.901881,2.821608 -1.044765,3.401572 -2.201915,8.492694 -3.217962,10.986462 -1.279941,4.396681 -3.338117,3.578002 -7.601783,3.627229 -2.397417,0.007 -4.963017,-0.224297 -6.276281,-2.022199 -1.824457,-2.497741 -2.178161,-5.0598 -2.846643,-7.67724 -0.605583,-2.371158 -1.382771,-8.551608 -2.218235,-9.800321 -0.65514,-0.979195 -1.497218,-0.55184 -1.407493,-0.821224 0.808542,-2.427515 2.400325,-4.4286237 3.940109,-6.1917067 2.632815,-3.014626 5.643162,-5.112705 8.15863,-5.859715 z"
        inkscape:label="scalp_back" 
        @click="toggleRegion(0)"        
      />
      <path
        id="24"
        :class="affected_regions[1] ? 'affected-region' : 'non-affected-region'"
        d="m 40.533867,24.004987 c -0.399294,-0.245815 -0.784776,-2.68123 -0.801084,-3.072409 -0.108924,-2.61244 0.486621,-5.758359 1.091792,-4.795173 0.352871,0.561628 0.0091,2.007081 -0.131552,3.520763 -0.193601,2.08348 0.240137,4.592633 -0.159156,4.346819 z m 21.28106,11.047987 c 0.632322,-0.192141 1.419623,-0.723321 1.861684,-1.202594 1.962859,-3.129334 1.909669,-5.527957 2.893497,-9.040467 0.9644,-2.536018 1.122754,-4.031856 1.779969,-5.903318 0.651072,-1.853974 1.803885,-4.104147 2.148747,-2.282734 0.756008,3.992908 0.654153,9.19669 -0.921906,13.956447 -2.829883,8.546344 -6.583715,8.34272 -5.847081,10.387173 0.151234,0.419734 0.04654,4.525401 0.378521,5.931579 0.01781,0.07545 0.445594,1.740673 1.128835,3.416418 0.705434,1.730176 1.463891,3.071097 1.05146,3.080599 -1.549889,0.03571 -7.929038,0.617771 -9.859592,0.605433 -2.471209,-0.01579 -8.357169,-0.640771 -9.938408,-0.671433 -0.416179,-0.0081 0.227758,-1.059126 0.783593,-2.557419 0.674159,-1.817241 1.277929,-3.843684 1.295542,-3.927915 0.119548,-0.57171 0.465555,-5.821283 0.465555,-6.411524 0,-0.590243 -1.100873,-1.760793 -1.879757,-2.601219 -2.124728,-2.292619 -4.267186,-4.888235 -5.145151,-14.111492 -0.141723,-1.488829 -0.07743,-4.579132 0.178848,-5.890042 0.273047,-1.396751 0.466812,-2.613361 0.73413,-2.610569 1.279114,0.01336 2.573874,14.058828 5.524738,17.914647 1.482487,1.937128 4.69413,2.31309 7.686371,2.3135 2.563867,3.52e-4 4.870403,-0.148939 5.680405,-0.39507 z m 10.52931,-11.41503 c -0.164842,-0.236189 -0.06148,-1.00025 -0.0703,-1.837498 -0.01322,-1.254158 -0.224028,-2.656733 -0.236908,-2.965689 -0.02148,-0.515207 -0.510151,-2.290388 -0.111364,-2.813286 0.865595,-1.134976 1.272168,3.985343 1.096439,5.387853 -0.126173,1.007 -0.453215,2.550507 -0.677867,2.22862 z"
        inkscape:label="head" 
        @click="toggleRegion(1)"        
      />
      <path
        id="25"
        :class="affected_regions[2] ? 'affected-region' : 'non-affected-region'"
        d="m 85.742887,67.713054 c 0,0 7.004052,4.443778 8.017489,6.657409 1.470579,3.212146 -0.203701,12.534445 0.05606,15.519584 0.37237,4.279162 -0.729442,7.97956 -0.729442,7.97956 0,0 -3.782424,0.64303 -5.896192,1.68406 -2.113768,1.041033 -3.556457,2.350853 -3.556457,2.350853 L 82.719621,89.176 c -0.116342,-1.61892 0.550351,-6.567459 -7.14e-4,-5.707603 -1.470627,2.294707 -1.250152,-5.820611 -0.717641,-7.816504 0.9068,-3.398758 1.573234,-5.089076 3.741621,-7.938839 z"
        inkscape:label="right_shoulder_back"
        @click="toggleRegion(2)"
      />
      <path
        id="26"
        :class="affected_regions[3] ? 'affected-region' : 'non-affected-region'"
        d="m 91.159173,106.9346 c -0.68051,-0.0123 -3.771438,1.37603 -2.729937,2.17732 0.406967,-0.0976 2.675087,-0.56096 3.253647,-1.06042 0.73917,-0.63813 -0.18695,-1.26024 -0.52371,-1.1169 z m -4.03704,9.90618 c -0.202604,0.0442 -0.62455,0.1526 -1.282299,-2.29997 -1.138562,-4.24538 -0.744346,-5.32692 -2.044976,-11.15908 0,0 4.018932,-1.81701 5.233452,-2.18646 1.214523,-0.36946 4.265823,-1.565303 4.426373,-1.404753 0.16054,0.16055 0.727398,0.19282 1.177458,5.048363 0.70004,7.55271 0.937922,7.82594 0.717832,9.81696 -0.76957,0.21327 -2.57803,0.39159 -4.70664,0.95549 -1.389231,0.36804 -3.151426,1.14883 -3.5212,1.22945 z"
        inkscape:label="right_elbow_back"
        @click="toggleRegion(3)"
      />
      <path
        id="27"
        :class="affected_regions[4] ? 'affected-region' : 'non-affected-region'"
        d="m 92.008948,135.42578 c -1.229223,-3.28577 -1.465753,-5.73854 -2.713614,-8.55952 -1.069046,-2.41678 -2.026874,-8.2583 -1.126601,-8.9166 1.068183,-0.78107 7.04368,-1.78875 7.54065,-1.29181 0.1728,0.17281 2.83453,6.50001 3.42133,9.93929 0.5868,3.43925 1.789357,6.40165 2.006697,8.95119 l 0.39068,4.58284 c 0,0 -2.814317,-0.30305 -3.880217,-0.23625 -1.0659,0.0668 -3.689373,0.74211 -3.689373,0.74211 z"
        inkscape:label="right_forearm_back"
        @click="toggleRegion(4)"
      />    
      <path
        id="28"
        :class="affected_regions[5] ? 'affected-region' : 'non-affected-region'"
        d="m 100.05216,161.54881 c -0.505188,-1.6454 -1.577276,-3.77609 -1.992647,-3.67966 -0.35567,0.0826 -0.115312,2.13121 0.09181,3.78347 0.30264,2.41418 -0.522528,1.94377 -0.825561,1.15791 -0.602363,-1.56211 -2.149288,-3.95192 -2.563488,-11.13323 -0.6642,-4.64189 -0.6966,-7.16937 -0.46696,-9.27714 0.68917,-0.1345 5.913016,-1.44339 7.520936,-0.76775 0.83281,0.34993 1.02472,1.43966 2.75871,4.07673 0.86876,1.3212 1.30617,2.85476 3.23279,4.641 4.00664,3.71476 4.00612,6.40437 0.0632,3.39374 -1.4547,-1.11073 -1.83703,-0.78641 -2.01424,-0.78641 -0.83335,0 -0.17488,2.95508 1.11624,6.02264 2.07092,4.92027 1.0459,5.54652 -1.26294,1.04911 -3.20412,-6.24126 -3.63406,-5.82115 -1.00847,0.64052 2.27399,5.59635 0.75454,5.90734 -1.78627,0.47548 -2.10239,-4.49462 -2.5592,-5.49308 -2.5592,-3.64101 0,0.45407 0.60129,1.7468 0.92517,2.59866 0.58221,1.53131 1.97024,4.22594 1.5273,5.13324 -0.21711,0.44469 -1.63958,-0.98825 -2.75638,-3.6873 z"
        inkscape:label="right_hand_back"
        @click="toggleRegion(5)"
      />
      <path
        id="29"
        :class="affected_regions[6] ? 'affected-region' : 'non-affected-region'"
        d="m 24.654358,99.496237 -5.15785,-1.39229 c 0,0 0.523702,-5.009103 0.409845,-8.104683 C 19.797024,87.026785 19.364314,79.755806 19.364314,79.755806 18.96451,77.264748 20.659225,70.420815 22.649518,69.42622 l 5.5928,-2.794849 c 1.625955,2.889879 2.527615,6.060373 3.012963,9.348593 0.153719,1.190783 -0.09703,7.696212 -0.641379,6.991522 -0.816923,-1.582839 -0.201691,2.886194 -0.25253,7.864148 -0.04812,4.71169 -0.970653,10.715366 -1.124845,10.690116 -0.237348,-0.0389 -2.345485,-1.35607 -4.582169,-2.029513 z"
        inkscape:label="left_shoulder_back"
        @click="toggleRegion(6)"
      />
      <path
        id="30"
        :class="affected_regions[7] ? 'affected-region' : 'non-affected-region'"
        d="m 23.279862,109.3705 c -0.513275,-0.12161 -1.080938,-0.41032 -1.434981,-0.39936 -1.351068,0.0418 -1.458968,1.09623 1.289205,1.77257 2.035415,0.50092 2.243594,-0.87621 0.145776,-1.37321 z m -6.034434,5.98667 0.598651,-4.04649 c 0.723432,-4.88993 -0.09385,-1.69802 0.544353,-8.57193 0.2454,-2.64318 0.946362,-3.039183 1.46132,-3.039183 0.41617,0 2.872744,0.351163 4.875457,1.231753 l 4.412207,1.94003 c -0.522272,2.05141 -0.149972,3.79717 -1.108896,6.7568 -0.255173,0.78756 -1.323813,3.49509 -1.915835,5.45665 -0.350359,1.16086 -0.608203,2.11375 -0.944692,2.01873 -0.433313,-0.12237 -2.230559,-0.86448 -3.180906,-1.18818 -0.950347,-0.32371 -2.655505,-0.45405 -4.741659,-0.55818 z"
        inkscape:label="left_elbow_back"
        @click="toggleRegion(7)"
      />
      <path
        id="31"
        :class="affected_regions[8] ? 'affected-region' : 'non-affected-region'"
        d="m 11.95718,140.00809 c 1.10158,-2.84221 1.450095,-9.43002 2.323488,-14.32512 0.819123,-4.59093 2.095309,-8.22417 2.964491,-8.94684 1.21232,0.48955 8.30744,1.51753 7.948716,2.52637 -0.566767,1.59391 -1.247644,5.76179 -1.572199,7.72227 -0.656712,3.96688 -1.74043,5.29742 -2.593503,7.96324 -0.811902,2.53716 -2.679743,5.82544 -2.679743,5.94143 0,0.11596 -1.43966,-0.46928 -3.391032,-0.50572 -1.695251,-0.0317 -3.313876,0.43362 -3.000218,-0.37563 z"
        inkscape:label="left_forearm_back"
        @click="toggleRegion(8)"
      />      
      <path
        id="32"
        :class="affected_regions[9] ? 'affected-region' : 'non-affected-region'"
        d="m 6.5825123,164.59007 c 0.0076,-0.39555 0.2987208,-2.45021 1.0808938,-4.34895 0.782173,-1.89876 1.7782332,-3.59848 1.7796962,-3.99406 0.0062,-1.68548 -1.055926,-0.44984 -2.906932,3.38161 -2.294329,4.7491 -3.6206234,3.30162 -1.4049204,-1.5381 1.506643,-3.29094 3.4585454,-6.49207 2.3545734,-6.49207 -0.335018,0 -0.7439032,0.72441 -1.9910642,1.72666 -3.7427672,3.00774 -3.6687534,0.42665 0.3119906,-3.22098 2.03925,-1.86859 2.5904374,-3.42935 3.2852554,-4.60697 0.5961857,-1.01046 1.2734729,-3.10218 2.1116249,-3.41626 0.76536,-0.2868 5.842409,-0.2117 6.305535,0.37803 1.136724,1.49005 0.995095,4.2486 0.89985,6.08971 -0.413016,7.98373 -2.300056,14.81079 -3.286325,14.81171 -0.212805,1.9e-4 -0.69646,-0.4959 -0.586802,-2.0784 0.132302,-1.90928 0.115709,-3.79453 -0.187135,-3.7573 -0.306575,0.0377 -0.903758,2.02176 -1.483307,4.01534 -0.930448,3.20063 -2.268918,3.99362 -2.268918,2.04518 0,-0.59524 0.01004,-1.48497 0.510301,-2.95312 1.235808,-3.6269 1.170156,-5.63521 -0.188387,-2.51506 -0.551157,1.26583 -1.0825432,2.86959 -1.6688482,4.21457 -1.008011,2.31232 -2.6968495,3.82624 -2.6670815,2.25846 z"
        inkscape:label="left_hand_back"
        @click="toggleRegion(9)"
      />
      <path
        id="33"
        :class="affected_regions[10] ? 'affected-region' : 'non-affected-region'"
        d="m 54.853018,86.469494 c -0.678741,-4.2e-5 0.03247,-2.94657 -0.814182,-2.974041 -0.846655,-0.02747 -0.967831,2.924464 -0.967831,2.924464 l -6.694777,-0.09832 -14.272647,0.261651 c 0,0 -0.95352,-1.450479 -0.41253,-1.704128 1.451513,-0.680557 0.944356,-3.218032 1.242103,-4.257758 1.279909,-4.469418 -1.782837,-11.717306 -3.373606,-14.99107 0,0 8.463228,-4.772322 11.505762,-6.410975 1.546508,-0.83292 4.355919,-4.148074 4.355919,-4.148074 3.172802,0.246404 9.670073,0.582378 12.202865,0.57321 2.532792,-0.0092 7.740435,-0.584257 9.684068,-0.713038 0.03499,-0.0023 3.048822,4.114524 6.755582,5.985011 4.017497,2.02729 6.683313,2.932178 9.878302,5.529723 l -2.707634,5.935846 c -1.984331,5.483366 -1.141675,8.406249 -1.058761,10.012577 0.06702,1.205135 1.044217,2.243021 1.375582,2.849866 0.244286,0.447373 -0.577611,1.625551 -1.213436,1.621516 -1.024645,-0.0065 -2.595138,-0.13039 -3.104734,-0.153354 -4.611151,-0.20779 -9.190586,-0.106333 -13.971397,-0.156383 -0.621914,-0.01092 0.709582,-2.682846 -0.418531,-2.682558 -1.128113,2.87e-4 -0.566387,2.585601 -0.932002,2.585539 -15.320269,-0.0026 11.742994,0.01146 -7.058115,0.0103 z"
        inkscape:label="body_up_back" 
         @click="toggleRegion(10)"
      />
      <path
        id="34"
        :class="affected_regions[11] ? 'affected-region' : 'non-affected-region'"
        d="m 36.861937,108.28927 c -0.299799,-2.36304 -0.999519,-4.78758 -1.463679,-7.17904 -0.544915,-2.807523 -0.877511,-5.588153 -1.524715,-8.448033 -0.522618,-2.30936 -1.162719,-4.191642 -1.247547,-4.369904 -0.136097,-0.286001 7.503947,-0.205223 10.904411,-0.224421 3.400464,-0.0192 8.362276,-0.04784 9.39106,0.07592 0.42163,0.05072 -0.25787,4.179525 -1.491005,7.683555 -1.170356,3.32565 -3.119036,5.247253 -2.513002,5.874573 1.929519,0.70418 3.515101,-4.229603 3.806603,-5.319533 0.937575,-3.5056 0.439252,-8.069628 1.703606,-8.287905 0.523252,-0.09033 2.083343,-0.06861 4.044934,-0.01756 1.326617,0.03452 3.445067,-0.340915 3.437131,-0.01568 -0.02081,0.852725 -0.319693,4.523865 0.465944,7.686135 0.283485,1.14106 0.675277,2.41598 1.108866,3.39821 0.788439,1.786093 1.886727,3.345023 2.471939,3.595583 0.585212,0.25057 0.0592,-1.03043 -0.140279,-1.52725 -0.504168,-1.255723 -1.47388,-2.451613 -2.072648,-5.103513 -0.648012,-2.86999 -1.522398,-7.936254 -0.358941,-7.897883 0.702271,0.02316 5.361521,-0.03578 9.680294,-0.03557 2.836165,1.37e-4 5.610668,-0.116172 6.722073,0.331642 -0.360888,1.491361 -1.252569,3.898341 -1.788074,5.768981 -0.695623,2.35941 -1.573446,7.434733 -1.742215,9.944683 -0.16588,2.46695 -0.16047,4.05719 -0.289657,4.05137 -6.846135,0.87958 -13.857544,1.80036 -20.719476,1.64439 -3.474909,-0.079 -6.951231,-0.44965 -10.428344,-0.7962 -2.637165,-0.26283 -5.319475,-0.73524 -7.957279,-0.83255 z"
        inkscape:label="body_down_back" 
         @click="toggleRegion(11)"
      />      
      <path
        id="35"
        :class="affected_regions[12] ? 'affected-region' : 'non-affected-region'"
        d="m 73.888537,133.62995 c -1.107613,-0.27003 -5.76826,-0.80762 -10.357002,-1.19461 -7.977308,-0.67278 -14.705599,-0.40692 -26.227533,1.03629 l -2.349296,0.29426 1.025514,-6.812 c 0.959651,-4.02843 1.062447,-8.10179 1.054704,-12.21376 l 0.02915,-4.34339 3.596143,0.57851 c 1.977866,0.20792 5.926424,0.72689 8.774571,1.031 3.581417,0.38242 7.662685,0.37093 13.233778,-0.101 4.430444,-0.37533 9.165355,-1.0227 10.522032,-1.03661 l 2.466682,-0.0253 0.125206,5.60094 c -0.0119,3.96087 0.577011,7.86323 1.246537,11.75296 0.476877,2.84812 0.871601,5.37261 0.877125,5.60995 0.01237,0.53041 -1.364153,0.46968 -4.017631,-0.17723 z"
        inkscape:label="lower_back" 
         @click="toggleRegion(12)"
       />
      <path
        id="36"
        :class="affected_regions[13] ? 'affected-region' : 'non-affected-region'"
        d="m 33.24995,155.79487 c -0.123826,-3.30288 -0.06452,-0.38151 0.127103,-3.4692 0.401839,-6.47508 0.483825,-14.52184 1.702753,-16.13764 1.159245,-1.53669 14.966025,-2.52933 20.971892,-2.38295 5.023006,0.12243 20.629202,1.24081 21.902992,2.55914 0.74343,0.76942 1.655359,9.61852 1.776841,19.13828 -0.179254,2.65157 0.342116,0.29788 0.01633,3.08014 l -12.204524,0.11902 h -9.764574 c -6.69e-4,-5.04377 5.8e-4,4.19994 -8.2e-4,-0.84383 l 1.582307,0.61732 c 3.89555,1.51983 15.57168,0.12514 17.400481,-2.07844 0.915623,-1.10325 0.413262,-1.09711 -4.063145,0.0497 -4.683393,1.19983 -12.679807,1.37476 -13.968109,0.30559 -0.76341,-0.63356 -0.953088,-1.8849 -0.959388,-6.32919 -0.0092,-6.10531 -0.381005,-8.28548 -1.360011,-7.96305 -0.379756,0.12505 -0.883087,2.91123 -1.221349,6.76062 -0.551996,6.2818 -0.63383,6.56404 -2.013837,6.94571 -2.980662,0.8243 -7.248204,0.46847 -11.21995,-0.93558 -4.647259,-1.64284 -4.315358,-1.57114 -4.315358,-0.93201 0,0.27323 1.618269,1.24421 3.596143,2.15768 3.098867,1.43121 4.292294,1.66059 8.630715,1.65885 l 5.034603,-0.002 v -0.28174 h -9.459849 c -5.202916,0 -11.834152,-0.22452 -12.135247,-0.54274 z"
        inkscape:label="buttocks" 
         @click="toggleRegion(13)"
      />
      <path
        id="37"
        :class="affected_regions[14] ? 'affected-region' : 'non-affected-region'"
        d="m 58.098243,160.36408 c 0.03237,0.32373 20.821339,-0.12723 21.874227,-0.31788 0.546349,-0.0989 0.132132,3.63633 0.04984,6.90508 -0.07478,2.97037 -0.66737,7.20617 -0.940103,9.35694 -0.220522,1.73904 -0.636676,4.1309 -1.072228,6.43277 -0.37385,1.97577 -0.74876,3.88523 -1.177367,5.25864 -0.268472,0.86028 -1.094687,4.6881 -1.019614,4.62123 0.123297,-0.10982 -3.948674,-0.22674 -8.260065,-0.0785 -3.765569,0.12947 -7.809134,0.54466 -8.155195,0.25454 -0.286684,-0.24034 -0.440933,-2.90471 -0.587664,-3.4497 -0.233181,-0.86608 -0.320446,-1.96448 -0.359721,-2.81094 -0.376738,-8.11952 -0.384484,-26.49592 -0.352111,-26.17219 z"
        inkscape:label="right_thigh_back"
        @click="toggleRegion(14)"
      />
      <path
        id="38"
        :class="affected_regions[15] ? 'affected-region' : 'non-affected-region'"
        d="m 58.486921,216.19258 c 0.148935,-2.18375 0.340391,-4.45667 1.642855,-10.0266 0.236516,-1.01145 0.701216,-4.85708 0.206264,-7.66694 -0.669629,-3.80151 -0.628387,-4.16227 -0.628387,-4.16227 0,0 2.716233,-0.0868 8.051679,-0.0868 6.167296,0 7.692321,-0.27161 7.692321,0.14169 0,0.33931 -0.355747,1.35024 -1.205924,4.88755 -1.346458,5.60217 -0.957776,3.25265 -0.421808,13.96718 0.471496,9.42566 0.09074,7.64684 -0.451531,7.56062 -2.391931,-0.38031 -15.044768,0.5297 -15.120602,-0.24869 -0.188165,-1.93141 0.06586,-1.84077 0.235133,-4.36574 z m 9.786309,-6.93448 c 0.544714,-0.46406 -1.800021,-0.88595 -3.104401,-0.55856 -2.264055,0.56823 -1.675453,1.45395 0.720514,1.08422 1.186727,-0.18312 2.259473,-0.41967 2.383887,-0.52566 z"
        inkscape:label="right_knee_back"
         @click="toggleRegion(15)"
      />
      <path
        id="39"
        :class="affected_regions[16] ? 'affected-region' : 'non-affected-region'"
        d="m 58.463922,222.58752 c -4.886278,0 15.482572,0.40465 15.923346,0.31355 0.226109,-0.0467 -0.0839,3.96336 -0.650729,8.0875 -0.538145,3.91542 -1.320742,7.91204 -1.621178,9.42863 -0.62031,3.13131 -1.736642,6.81703 -2.270479,9.30342 -0.533837,2.48639 -1.225497,4.58939 -1.247012,5.86413 -0.0052,0.30843 -2.28578,-0.0679 -4.392789,-0.13828 -2.247126,-0.075 -4.807227,0.30438 -4.722248,0.14308 0.164658,-0.31243 0.301947,-3.39947 0.40568,-5.41215 0.129342,-2.50956 -0.121,-2.83339 -0.246208,-5.34316 -0.198577,-2.92938 -0.54769,-5.89031 -0.829771,-8.81205 -0.228358,-2.49491 -0.242012,-5.14772 -0.306348,-6.60129 -0.100823,-2.27789 -0.04226,-6.83338 -0.04226,-6.83338 z"
        inkscape:label="right_calf_back"
         @click="toggleRegion(16)"
      />
      <path
        id="40"
        :class="affected_regions[17] ? 'affected-region' : 'non-affected-region'"
        d="m 61.000483,274.53467 c 0,0 -1.700383,-0.4419 -2.182012,-1.09851 -0.609606,-0.83108 -0.496669,-3.05191 -0.496669,-3.05191 0,0 0.63694,-7.91848 0.884286,-10.00287 0.03567,-0.3006 0.281178,-3.064 0.297565,-3.08068 0.130014,-0.13235 4.641171,-0.0416 4.641171,-0.0416 4.129696,0.09 4.187966,-0.20735 4.129712,0.33107 -0.168377,1.55625 -0.0516,2.68041 0.298063,3.8694 0.513924,1.74756 1.713115,3.08049 4.231231,3.82098 2.910659,0.85593 3.323156,1.83648 3.323156,3.022 0,1.23268 -0.699627,1.78292 -4.890745,3.84629 -5.453655,2.68495 -7.460118,3.15264 -10.235758,2.38578 z"
        inkscape:label="right_foot_back"
        @click="toggleRegion(17)"
      />
      <path
        id="41"
        :class="affected_regions[18] ? 'affected-region' : 'non-affected-region'"
        d="m 33.21812,159.48885 c 0.07283,-0.2816 1.860771,0.002 5.050903,0.026 3.359237,0.0256 7.613612,0.1733 10.91496,0.24346 2.994381,0.0636 5.204726,0.0389 5.251961,0.26166 0.148768,0.7015 0.107254,20.46688 0.107254,20.46688 0.01069,1.72584 0.136265,4.38347 0.04809,6.41714 -0.08818,2.03367 -0.946634,5.80693 -0.90396,5.88236 0.0879,0.15536 -15.049487,-0.35101 -16.429971,0.0466 -0.624159,0.17979 -0.792698,-2.13278 -1.410796,-5.20034 -0.29269,-1.45259 -0.80271,-2.93569 -1.168736,-4.54239 -0.570621,-2.5048 -0.897281,-5.11149 -1.125332,-6.84355 -0.224746,-1.70695 -0.640896,-4.23652 -0.783144,-6.65671 -0.21255,-3.29532 -0.281914,-7.27581 -0.06601,-8.11063 z"
        inkscape:label="left_thigh_back"
         @click="toggleRegion(18)"
      />
      <path
        id="42"
        :class="affected_regions[19] ? 'affected-region' : 'non-affected-region'"      
        d="m 38.788526,214.11498 c -0.07648,-2.1799 0.290633,-4.0713 0.346543,-5.35179 0.271006,-6.20669 0.595531,-3.35259 -0.653008,-8.90955 0,0 -1.170123,-4.90669 -0.978408,-5.17432 0.191715,-0.26763 4.850244,-0.0635 7.506687,-0.1323 5.081543,-0.13162 8.245611,-0.22192 8.362313,-0.15032 0.116713,0.0717 0.219646,0.63588 -0.180182,3.77333 -0.347802,2.7292 -0.01993,5.32682 -0.144906,8.4191 -0.04727,1.16976 0.451911,2.96616 0.567465,4.91855 0.08858,1.49658 0.454918,2.88879 0.517456,3.78478 0.310939,4.45485 0.678656,5.49429 0.05824,5.41921 -2.151351,-0.26039 -5.134671,-0.0578 -7.632384,-0.1384 -2.497712,-0.0806 -5.736953,0.54885 -7.712414,0.0705 -0.497259,-0.12041 0.06029,-3.17416 0.01521,-4.45918 z m 4.492467,-2.19976 c 0.19558,-0.31647 1.052351,-0.57936 1.903948,-0.5842 2.081474,-0.0119 3.437424,-0.84591 2.16746,-1.33325 -1.175608,-0.45111 -5.061287,0.83224 -5.369949,1.77359 -0.279992,0.85397 0.786638,0.9721 1.298541,0.14386 z"
        inkscape:label="left_knee_back"
         @click="toggleRegion(19)"
      />      
      <path
        id="43"
        :class="affected_regions[20] ? 'affected-region' : 'non-affected-region'"
        d="m 47.071959,222.75158 c 2.127001,0 7.317098,-0.38621 7.394827,-0.45906 0.02929,-0.0274 0.114139,4.50754 -0.08331,9.16014 -0.137691,3.24456 -0.528616,6.527 -0.682797,8.44065 -0.21146,2.62457 -0.653593,5.23588 -0.686854,7.21264 -0.03156,1.87579 -0.08098,3.57614 0.01426,5.06811 0.08472,1.32728 0.315318,2.4975 0.293328,3.35924 -0.0056,0.21896 -1.944232,-0.006 -4.428959,-0.0345 -2.455604,-0.0286 -4.91758,0.0206 -4.91758,0.0206 -0.093,-1.10644 -0.398134,-2.48913 -0.662495,-3.77759 -0.626579,-3.05387 -0.760299,-3.45441 -1.381855,-6.5093 -0.334327,-1.64319 -0.964489,-3.73868 -1.426522,-5.81812 -0.477007,-2.14683 -1.242689,-6.35349 -1.43406,-9.36131 -0.03706,-0.58254 -0.316269,-1.40697 -0.376979,-2.10867 -0.232804,-2.69075 -0.421363,-5.52271 -0.311415,-5.51859 0,0 6.563406,0.32576 8.690407,0.32576 z"
        inkscape:label="left_calf_back"
         @click="toggleRegion(20)"
      />
      <path
        id="44"
        :class="affected_regions[21] ? 'affected-region' : 'non-affected-region'"
        d="m 45.727462,273.27525 c -3.178123,-1.03111 -9.524174,-4.46158 -9.856738,-5.32823 -0.517128,-1.34763 1.001416,-3.06114 3.249218,-3.66641 1.244639,-0.33514 3.167743,-1.19724 3.497903,-1.75952 0.244039,-0.41561 0.832764,-1.8702 1.078736,-2.54105 0.0868,-0.23674 0.115285,-2.95013 0.15632,-2.92552 0.157335,0.0944 3.416414,0.33261 4.996437,0.34916 1.580023,0.0165 4.470651,-0.23096 4.470651,-0.23096 -0.09213,1.21106 0.153024,5.02885 0.211604,6.13958 0.228585,4.33412 0.983938,7.74582 -0.691285,9.37434 -1.238013,1.2035 -4.412575,1.46459 -7.112846,0.58851 z"
        inkscape:label="left_foot_back"
        @click="toggleRegion(21)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "AdultDiagramBack",
  props: {
    selectedRegions: {
      type: Array,
      default: () => Array(22).fill(false),
    },
  },
  emits: ["update-regions"],

  data() {
    return {
      // an empty array of size 22
      affected_regions: this.selectedRegions,
    };
  },
  watch: {
    selectedRegions(newValue) {
      this.affected_regions = newValue;
    },
  },
  created() {
    this.$emit("update-regions", this.affected_regions);
  },
  methods: {
    toggleRegion(id) {
      this.affected_regions[id] = !this.affected_regions[id];
      this.$emit("update-regions", this.affected_regions);
    },
  },
};
</script>
