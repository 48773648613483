<template>
  <div class="form-container">
    <div class="header-row">
      <h1 class="title">
        Step 2: Photos Upload
      </h1>
      <div
        class="info-icon"
        @click="toggleHelpText"
      >
        <img
          src="@/assets/info_icon.svg"
          alt="Info"
        >
      </div>
    </div>
    <div
      v-if="showHelpText"
      class="help-text"
    >
      <p>
        This form consists of two steps: 1. Select body parts with eczema, and 2. Photos upload.
        <!-- <br> Instructions in more detail about photo upload... -->
      </p>
    </div>
    <hr class="separator">
    <template
      v-if="!regions.size"
    >
      <div class="instruction-text">
        <p class="bold-text">
          There are no photos to be upload.
          <span v-if="genitalsSelected">(Excluding the genital region)</span>
        </p>
        <p class="italic-text">
          If this was a mistake press the back button and select the parts of body.
        </p>
        <p class="italic-text">
          Otherwise press Submit to finish the questionnaire.
        </p>
      </div>
    </template>
    <template
      v-else
    >
      <div class="instruction-text">
        <p class="bold-text">
          <!-- Please upload images of the affected regions that you identified in the previous section. -->
          <span v-if="genitalsSelected">(Excluding the genital region)</span>
        </p>
        <!-- <p class="italic-text"> -->
          <!-- For each of the regions, upload an overview image, and between 1 to 4 close-up shots of the affected areas. -->
          <!-- You can upload between 1 to 10 photos. -->
        <!-- </p> -->
      </div>

      <template
        v-for="(region, index) in regions"
        :key="region"
      >
        <template v-if="region.toLowerCase() !== 'genitals'">
          <h2 class="region-text">
            {{ region }}
          </h2>
          
          <h3 class="region-text2">You can upload between 1 to 10 photos.</h3>

          <ImageUpload
            :ref="'imageUploadComponent_' + index"
            :uuid="uuid"
            :all-images="answers[region]"
            :region="region"
            @update="updateAnswers(region, $event)"
            @all-images-uploaded="updateImagesUploaded(index, $event)"
            @checkbox-change="handleCheckboxChange(index, $event)"
          />
        </template>
      </template>
    </template>
  </div>
</template>
  
<script>
import ImageUpload from './ImageUpload.vue';

export default {
  name: 'ImageUploadPage',
  components: { ImageUpload },
  beforeRouteLeave(to, from, next) {
    this.deleteImages().then(() => {
      next();
    });
    this.emit('images-deleted');
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    answers: {
      type: Object,
      default: () => ({}),
    },
    regions: {
      type: Set,
      required: true,
      default: () => new Set(),
    },
    genitalsSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-answers', 'all-images-uploaded', 'images-deleted'],
  data() {
    return {
      showHelpText: false,
      localAnswers: {},
      imagesUploaded: Array(this.regions.size).fill(false),
    };
  },
  computed: {
    // allQuestionsAnswered() {
    //   return this.imagesUploaded.every(imageUploaded => imageUploaded);
    // },

    allQuestionsAnswered() {
      return this.regions
        .has('genitals') ? this.imagesUploaded.filter((_, index) => {
          const region = Array.from(this.regions)[index];
          return region.toLowerCase() !== 'genitals';
        }).every(imageUploaded => imageUploaded) : this.imagesUploaded.every(imageUploaded => imageUploaded);
    },
    sortedRegions() {
      // Define the desired order of regions
      const order = ["scalp front", "face", "neck", "right shoulder front", "right elbow front", "right forearm front", "right hand front", "left shoulder front",
       "left elbow front", "left forearm front", "left hand front", "body up front", "body down front", "belly", "genitals", "right thigh front",
       "right knee front", "right calf front", "right foot front", "left thigh front", "left knee front", "left calf front", "left foot front",
       "scalp back", "head", "right shoulder back", "right elbow back", "right forearm back", "right hand back", "left shoulder back", "left elbow back",
       "left forearm back", "left hand back", "body up back", "body down back", "lower back", "buttocks", "right thigh back", "right knee back",
       "right calf back", "right foot back", "left thigh back", "left knee back", "left calf back", "left foot back"];

      // Sort the regions based on the defined order
      return Array.from(this.regions).sort((a, b) => order.indexOf(a) - order.indexOf(b));
    },    
  },
  watch: {
    allQuestionsAnswered(newValue) {
      this.$emit('all-images-uploaded', newValue)
    }
  },
  created() {
    if (this.answers) {
      this.localAnswers = { ...this.answers };
    }
    if (this.regions.size === 0) {
      this.$emit('all-images-uploaded', true);
    }
  },  
  methods: { 
    handleCheckboxChange(index, newValue) {
      // the checkbox in ImageUpload.vue has been changed
      // if newValue is true, that section is considered as "answered"
      this.imagesUploaded[index] = newValue;
    },    
    toggleHelpText() {
      this.showHelpText = !this.showHelpText;
    },
    updateAnswers( region, images ) {
      this.localAnswers[region] = images;
      this.$emit('update-answers', {answers: this.localAnswers } );
    },
    updateImagesUploaded( index, allImagesUploaded ) {
      this.imagesUploaded[index] = allImagesUploaded;
    },
    deleteImages() {
      return new Promise((resolve) => {
        // Loop through all ImageUpload component instances and call remove_all_images on each
        const promises = Object.keys(this.$refs).map(refKey => {
          const component = this.$refs[refKey];
          if (component && typeof component.remove_all_images === 'function') {
            return component.remove_all_images();
          }
          return Promise.resolve();
        });
        
        // Wait for all remove_all_images calls to complete
        Promise.all(promises).then(() => {
          this.$emit('images-deleted');
          resolve();
        }).catch(error => {
          console.error('Error deleting images:', error);
          resolve(); // Still resolve to allow navigation
        });
      });
    },
  },
};
</script>
  
<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
}

.title {
  margin-right: 10px;
  margin-bottom: 0.25em;
  margin-left: 0.2em;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 2em;
}

.region-text {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 1.375em;
  margin: 0 0 0 3em;
}

.region-text2 {
  font-family: 'Mulish', sans-serif;
  font-weight: 300;
  font-size: 1em;
  margin-left: 3em;
}

.info-icon {
  justify-content: center;
  align-items: center;
  /* translate both in x and y direction */
  margin-left: 1.2em;
  transform: translateY(35%);
  width: 1.5em;
  cursor: pointer;
}

.info-icon img {
  width: 100%;
}

.help-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: #830505;
  margin-bottom: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  line-height: 1.5em;
  text-align: left;
}

.separator {
  width: calc(100% + 40px); /* adding twice the padding to the width */
  margin-left: -20px;
  margin-right: -20px;
  /* border-top: 1px solid #000; */
}

.instruction-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1;
  margin: 0px 0px 10px 3em;
  text-align: left;
}

.bold-text {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

</style>
