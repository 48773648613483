<template>
  <div class="submit-container">
    <!-- <p class="thanks">
      Thank you!
    </p>
    <p class="form-submitted">
      The form has been submitted.
    </p> -->
    <div class="form-submitted">
      <p>
        <!-- For testing purposes you can check the results by clicking the button below. -->
        Your form has been completed. Click Next to continue.
      </p>
      <button
        class="results-button"
        @click="goToResults"
      >
        <!-- Go to results page -->
        Next
      </button>
    </div>
    <!-- <p class="more-info">
      For any further queries contact <a href="mailto:rapideczematrials@nottingham.ac.uk">rapideczematrials@nottingham.ac.uk</a>.
    </p> -->
    <!-- <img
      src="@/assets/done-icon.svg"
      class="done-icon"
    > -->
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'SubmittedPage',
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  methods: {
    async goToResults() {
      try {
        const formData = await api.getResults(this.uuid);
        const next_survey_url = formData.message;
        
        // Navigate to the next survey, open in the current tab.
        window.location.href = next_survey_url;
        
        // Alternatively, use Vue Router for internal navigation
        // this.$router.push('/results/' + this.uuid);
      } catch (error) {
        console.error("Error navigating to the next page:", error);
      }
    },
  },
}

</script>

<style scoped>

.submit-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  align-self: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.thanks, .form-submitted {
  margin-bottom: 0em;
}

.thanks {
  font-size: 2em;
}

.form-submitted {
  font-size: 1.2em;
}

.more-info {
  font-size: 0.8em;
  margin-top: 0.5em;
}

.done-icon {
  /* width: 100%; */
  height: 150px;
  max-height: 300px;
  margin-top: 2em;
}

.results-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  width: 60%;
  max-width: 400px;
  margin: 1em 5px;
  padding: 10px 20px;
  border-radius: 7px;
  border: none;
  background-color: #064896;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

</style>