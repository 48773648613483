<template>
  <div class="form-container">
    <template v-if="showSectionTitle">
      <div class="header-row">
        <h1 class="title">
          Step 1: Select all body parts that have eczema
        </h1>
      
        <div
          v-if="!isResultsPage"
          class="info-icon"
          @click="toggleHelpText"
        >
          <img
            src="@/assets/info_icon.svg"
            alt="Info"
          >
        </div>
      </div>
      <div
        v-if="showHelpText"
        class="help-text"
      >
        <p>
          This form consists of two steps: 1. Select body parts with eczema, and 2. Photos upload.
          <!-- <br> There are two questionnaires, POEM (Patient Oriented Eczema Measure) and PO-SCORAD (Patient Oriented SCORing Atopic Dermatitis). -->
        </p>
      </div>
    </template>
    <hr class="separator">
    <div class="questionnaire-title-container">
      <h2 class="questionnaire-title">
        {{ questionnaireTitle }}
      </h2>
      <!-- <h2
        v-if="isResultsPage"
        class="questionnaire-score"
      >
        Score: {{ score }}
      </h2> -->
    </div>
    <!-- Questionnaire data below -->
    <div
      v-for="(section, index) in content"
      :key="index"
      class="questionnaire-content"
    >
      <h3
        v-if="content.length > 1"
        class="section-title"
      >
        {{ section.section }}
      </h3>
      <div
        v-for="(question, qIndex) in section.questions"
        :key="qIndex"
      >
        <MultipleQuestion
          v-if="question.type === 'multipleChoice'"
          :key="qIndex"
          :question-id="question.id"
          :question-text="question.text"
          :options="question.options"
          :selected-option="localAnswers[question.id]"
          @update="updateAnswers"
        />
        <DiagramQuestion
          v-if="question.type === 'diagram'"
          :question-id="question.id"
          :question-text="question.text"
          :selected-region-keys="localAnswers[question.id]"
          :is-results-page="isResultsPage"
          @update="updateAnswers"
          @update-regions="updateRegions"
          @genitals-selected="genitalsSelected"
        />
        
        <h3>
          <p class="help-text"> 
            If you encountered any difficulties, please contact <a href="mailto:rapideczematrials@nottingham.ac.uk">rapideczematrials@nottingham.ac.uk</a>.
          </p>
        </h3>

      </div>
    </div>
  </div>
</template>
  
<script>
import MultipleQuestion from './MultipleQuestion.vue';
import DiagramQuestion from './Diagram/DiagramQuestion.vue';

export default {
  name: 'QuestionnaireBase',
  components: { MultipleQuestion, DiagramQuestion },
  props: {
    showSectionTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    isResultsPage: {
      type: Boolean,
      required: false,
      default: true,
    },
    score: {
        type: String,
        required: false,
        default: 'NA',
      },
    questionnaireTitle: {
      type: String,
      required: true,
    },
    content: {
      type: Array,
      required: true,
      default: () => [],
    },
    answers: {
      type: Object,
      default: () => ({}),
    },
  },
emits: ['update-answers', 'update-regions', 'all-questions-answered', 'genitals-selected'],
  data() {
    return {
      showHelpText: false,
      localAnswers: {},
    };
  },
  computed: {
    // TODO: if there are no regions containing eczema for SCORAD, should just skip whole questionnaire
    allQuestionsAnswered() {
      return this.content.every(section =>
        section.questions.every(question => question.id in this.localAnswers))
    }
  },
  watch: {
    allQuestionsAnswered(newValue) {
      this.$emit('all-questions-answered', newValue)
    }
  },
  created() {
    if (this.answers) {
      this.localAnswers = { ...this.answers };
    }
  },
  methods: {
    toggleHelpText() {
      this.showHelpText = !this.showHelpText;
    },
    updateAnswers({ questionId, selectedOption }) {
      if (selectedOption === undefined || selectedOption === null || (typeof selectedOption === 'object' && Object.keys(selectedOption).length === 0)) {
        delete this.localAnswers[questionId];
      }
      else {
        this.localAnswers[questionId] = selectedOption;
      }
      this.$emit('update-answers', {answers: this.localAnswers } );
    },
    updateRegions(regions) {
      this.$emit('update-regions', regions);
    },
    genitalsSelected(value) {
      this.$emit('genitals-selected', value);
    },
  },
};
</script>
  
<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
}

.title {
  margin-right: 10px;
  margin-bottom: 0.25em;
  margin-left: 0.2em;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 2em;
}

.info-icon {
  justify-content: center;
  align-items: center;
  /* translate both in x and y direction */
  margin-left: 1.2em;
  transform: translateY(35%);
  width: 1.5em;
  cursor: pointer;
}

.info-icon img {
  width: 100%;
}

/* .info-icon img:hover {
  filter: brightness(1000%);
} */

.help-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: #830505;
  margin-bottom: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  line-height: 1.5em;
  text-align: left;
}

.separator {
  width: calc(100% + 40px); /* adding twice the padding to the width */
  margin-left: -20px;
  margin-right: -20px;
  /* border-top: 1px solid #000; */
}

.questionnaire-content {
  width: 100%;
  margin-bottom: 1em;
}

.questionnaire-title-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
}

.questionnaire-title {
  /* Add your styling here */
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 1em;
}

.questionnaire-score {
  /* Add your styling here */
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 1.8em;
  margin-top: 0.3em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.section-title {
  /* Add your styling here */
  font-family: 'Roboto', sans-serif;
  text-align: left;
  font-weight: 700;
  font-size: 1em;
  color: #808080;
  margin-top: 0.5em;
  margin-left: 3em;
}

@media (max-width: 600px) {
  .questionnaire-title-container {
    flex-direction: column;
  }
  .questionnaire-title {
    margin-left: 0;
  }
  .questionnaire-score {
    position: relative;
    margin: auto;
    align-self: center;
    left: 0;
    transform: translateX(0);
  }
}

</style>
