<template>
  <!-- Checkbox outside of both image-upload-container and upload-area -->
  <div class="checkbox-container">
    <input
      v-model="checkboxValue"
      type="checkbox"
    >
    <label for="checkbox">I do not want to upload any photos for this region.</label>
  </div>

  <div
    class="image-upload-container"
    :class="{ disabled: checkboxValue }"
  >
    <!-- div for the SVG image on the left side -->
    <div class="region-svg">
      <!-- Dynamically load the SVG based on the region prop -->
      <img
        :src="require(`@/assets/svg/${region}.svg`)"
        :alt="`${region} SVG`"
        class="region-icon"
      />      
    </div>

    <div
      class="upload-area"
      @dragover.prevent
      @drop="onDrop"
    >

      <!-- drag-and-drop area for uploading images -->
      <div class="upload-icon">
        <img
          src="@/assets/upload-icon.svg"
          alt="Upload Icon"
        >
      </div>
      <p class="upload-instructions">
        Drag & drop files or
        <button
          class="browse-button"
          @click="browseFiles"
        >
          Browse
        </button>
      </p>
      <input
        ref="fileInput"
        type="file"
        style="display: none"
        accept="image/png, image/jpeg"
        multiple
        @change="(event) => onImageUpload(event.target.files)"
      >
      <p class="upload-support">
        Supported formates: JPEG, PNG
      </p>
    </div>
    <div
      v-if="local_images.length !== 0"
      class="uploaded-images"
    >
      <p :class="{ 'upload-text': true, 'image-missing': imagesMissing }">
        Images ({{ local_images.length }})
      </p>
      <!-- display the images that the user has uploaded -->
      <!-- Display the images with sequential numbering -->
      <div
        v-for="(image, index) in local_images"
        :key="`${image.name}_${image.size}_${image.lastModified}`"
        class="uploaded-image-bar"
      >
        <span class="image-number">{{ index + 1 }}</span>
        <uploaded-image
          :image="image"
          class="uploaded-image-bar"
          @cancel-upload="cancelUpload"
        />
      </div>      
      <!-- <image-placeholder
        v-if="local_images.length === 0"
        text="missing image"
      />
      <uploaded-image
        v-for="image in local_images"
        :key="`${image.name}_${image.size}_${image.lastModified}`"
        :image="image"
        class="uploaded-image-bar"
        @cancel-upload="cancelUpload"
      /> -->
    </div>
  </div>
</template>

<script>
import UploadedImage from "./UploadedImage.vue";
// import ImagePlaceholder from "./ImagePlaceholder.vue";
import api from "@/services/api";

let imageIdCounter = 0;

export default {
  name: "ImageUpload",
  // components: { UploadedImage, ImagePlaceholder },
  components: { UploadedImage },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    allImages: {
      type: Object,
      required: false,
      default: () => ({ images: [] }),
    },
    region: {
      type: String,
      required: true,
    },
  },
  emits: ["update", "checkbox-change", "all-images-uploaded"],
  data() {
    return {
      local_images: this.allImages.images,
      checkboxValue: false,
    };
  },
  computed: {
    imagesMissing() {
      return this.local_images.length === 0;
    },
    // TODO: fix bug, when leaving and coming back to page, evaluates to false at first
    allImagesUploaded() {
      const anyMissing = this.imagesMissing;
      return (
        !anyMissing &&
        this.local_images.every((image) => image.status === "complete")
      );
    },
    local_allImages() {
      return { images: this.local_images };
    },
  },
  watch: {
    allImagesUploaded() {
      // TODO: also check status of images (need to be complete)
      this.$emit("all-images-uploaded", this.allImagesUploaded);
    },
    local_allImages() {
      this.$emit("update", this.local_allImages);
    },
    checkboxValue(newValue) {
      // Emits the 'checkbox-change' event with the new checkbox value
      this.$emit("checkbox-change", newValue);
      this.checkboxValue = newValue;

      if (newValue) {
        this.remove_all_images();
      }
    },
  },
  methods: {
    remove_all_images() {
      return new Promise((resolve, reject) => {
        Promise.all(this.local_images.map(image => this.cancelUpload(image)))
          .then(results => {
            const allCancelled = results.every(result => result);
            if (allCancelled) {
              this.local_images = [];
              resolve();
            } else {
              const failedUploads = this.local_images.filter(image => !image.cancelled);
              this.local_images = failedUploads;
              Promise.all(failedUploads.map(image => this.cancelUpload(image)))
                .then(() => {
                  this.local_images = [];
                  resolve();
                })
                .catch(error => {
                  console.error("Failed to cancel some uploads even after retrying.", error);
                  this.checkboxValue = false;
                  reject(error);
                });
            }
          })
          .catch(error => {
            console.error("Error cancelling uploads.", error);
            reject(error);
          });
      });
    },
    onImageUpload(files) {
      const totalImages = this.local_images.length;
      if (totalImages + files.length > 10) {
        alert("You can only upload up to 10 photos at a time.");
        return;
      }
      for (let i = 0; i < files.length; i++) {
        // add each file to images array with initial progress of 0
        if (files[i].type !== "image/png" && files[i].type !== "image/jpeg") {
          alert("Invalid file type. Only PNG and JPEG files are allowed.");
          continue;
        }

        const matchesFile = (img, file) =>
          img.name === file.name &&
          img.size === file.size &&
          img.lastModified === file.lastModified;

        const existingImage = this.local_images.find((img) =>
          matchesFile(img, files[i])
        );

        if (existingImage) {
          // if the file already exists, skip it
          continue;
        }
        const objectUrl = URL.createObjectURL(files[i]);

        const image = {
          name: files[i].name,
          id: imageIdCounter++,
          progress: 0,
          status: "uploading",
          preview: objectUrl,
          size: files[i].size, // to check for duplicates
          lastModified: files[i].lastModified, // to check for duplicates
        };
        this.uploadImage(files[i], image);
        this.local_images.push(image);
      }

      // reset the input value so the change event will fire if the user selects the same file again
      event.target.value = null;
    },
    onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.onImageUpload(files);
    },
    async uploadImage(file, image) {
      api
        .uploadImage(this.uuid, file, this.region, image.id, (progress) => {
          // note: direct assignment to image.progress does not trigger a re-render
          // so we need to create a new object and replace the old one
          // images could be replaced with a map for more efficiency
          const updatedImage = { ...image, progress };

          this.updateImage(updatedImage);
        })
        .then(() => {
          const updatedImage = { ...image, progress: 100, status: "complete" };
          this.updateImage(updatedImage);
        })
        .catch(() => {
          const updatedImage = { ...image, progress: 0, status: "error" };
          this.updateImage(updatedImage);
        });
    },
    findImageIndex(image) {
      return this.local_images.findIndex(
        (img) =>
          img.name === image.name &&
          img.size === image.size &&
          img.lastModified === image.lastModified
      );
    },
    updateImage(image) {
      // find the image with the given name and replace it with the new one
      const index = this.findImageIndex(image);
      this.local_images.splice(index, 1, image);
    },
    cancelUpload(image) {
      const imageIndex = this.findImageIndex(image);

      if (imageIndex >= 0) {
        const imageId = this.local_images[imageIndex].id;

        // Return the promise from the API call
        return api
          .cancelUpload(this.uuid, this.region, imageId)
          .then(() => {
            // Remove the image from the array using splice
            this.local_images.splice(imageIndex, 1);
            // Return a success indicator
            return true;
          })
          .catch((error) => {
            // Log cancellation error
            console.error("Error cancelling uploads.", error);
            // Return a failure indicator
            throw error;
          });
      } else {
        // Return a promise indicating that the image was not found
        return Promise.resolve(false);
      }
    },
    browseFiles() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>
/* .image-upload-container {
  box-sizing: border-box;
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: rgba(6, 72, 150, 0.28);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2em 1em;
  font-family: "Mulish", sans-serif;
  margin: 2px;
  margin-bottom: 2em;
  min-height: 400px;
  position: relative; /* Ensure relative positioning for SVG */
/* } */

.image-upload-container {
  box-sizing: border-box;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(6, 72, 150, 0.28);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2em 1em;
  font-family: "Mulish", sans-serif;
  margin: 2px;
  margin-bottom: 2em;
  max-height: 300px; /* Set a max-height */
  height: 300px; /* Ensure consistent height */
  position: relative;
  overflow: hidden; /* Prevent image overflow */
}

/* Add styles to visually indicate the disabled state */
.image-upload-container.disabled {
  pointer-events: none; /* Disable pointer events */
  opacity: 0.5; /* Reduce opacity to visually indicate the disabled state */
}

/* SVG container */
.region-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%; /* Ensure the SVG container does not exceed the container's height */
  max-width: 20%; /* Adjust width as needed */
  padding-right: 1em;
  flex-shrink: 0; /* Prevent shrinking */
}

.region-icon {
  max-height: 100%; /* Ensure SVG height stays within container */
  max-width: 100%; /* Set max-width to 100% for responsive scaling */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image scales properly */
}

.upload-area {
  flex-grow: 1;
  max-width: 30%; /* Constrain the width */
  max-height: 100%; /* Ensure the height is contained */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8ff;
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  padding: 1em;
  text-align: center;
  margin: auto; /* Center the upload area within the container */
}

/* .upload-area {
  min-height: 200px;
  max-height: 350px;
  flex: 0.4;
  background-color: #f8f8ff;
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add margin to create space between upload area and checkbox */
/* } */



.upload-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-instructions {
  font-size: 1;
  font-weight: 700;
  margin-bottom: 0.5em;
  color: #0f0f0f;
  text-align: center;
}

.browse-button {
  background: none;
  color: #483ea8;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.upload-support {
  font-size: 0.75em;
  font-weight: 400;
  margin-bottom: 0.5em;
  /* line-height: 18px; */
  color: #676767;
  text-align: center;
}

/* Uploaded images area */
.uploaded-images {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  max-width: 100%;
  overflow-y: auto; /* Add scroll if content overflows */
  overflow-x: hidden; /* Prevent horizontal scroll unless necessary */
  max-height: 1200px;
}

/* .uploaded-image-bar {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.uploaded-image-bar:hover {
  border: 2px solid #6d5dfa;
} */

.uploaded-image-bar {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1em; /* Add spacing between images */
  /* Remove or adjust any hover effects here */
}


/* Remove the border and hover effect */
.uploaded-image-bar:hover {
  border: none; /* Remove the purple border */
  transform: none; /* Remove any scaling effect */
  box-shadow: none; /* Remove any shadow effect */
}

.upload-text {
  font-size: 0.875em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #676767;
}

.image-missing {
  color: #e41d1d;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .region-svg {
    max-width: 25%; /* Adjust as needed for smaller screens */
  }
  
  .upload-area {
    max-width: 65%; /* Adjust as needed for smaller screens */
  }
}

@media (max-width: 800px) {
  .image-upload-container {
    flex-direction: column; /* Stack items vertically */
    height: auto; /* Allow container height to adjust */
    max-height: 400px; /* Adjust max-height as needed */
    overflow-y: auto; /* Add scroll if content overflows */
    overflow-x: auto; /* Add scroll if content overflows */
  }

  .region-svg {
    max-width: 50%; /* Make SVG take full width on small screens */
    margin-bottom: 1em; /* Add space between SVG and upload area */
  }

  .upload-area {
    max-width: 100%; /* Make upload area take full width on small screens */
    margin: 0; /* Remove margin to ensure full-width layout */
  }
}

@media (max-width: 600px) {
  .upload-area {
    max-height: 200px; /* Adjust height for very small screens */
    flex: none; /* Prevent flex shrinking issues */
  }

  .uploaded-images {
    box-sizing: border-box;
    flex: none; /* Prevent flex shrinking issues */
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  .region-svg {
    max-width: 25%; /* Adjust SVG width for small screens */
    margin-bottom: 1em; /* Add space between SVG and upload area */
  }
  
  .image-upload-container {
    height: auto; /* Let the container height adjust to the content */
    max-height: 300px; /* Adjust max-height as needed */
    overflow-y: auto; /* Add scroll if content overflows */
    overflow-x: auto; /* Add scroll if content overflows */
  }  
}

.checkbox-container {
  font-family: 'Mulish', sans-serif;
  font-weight: 300;  
  margin-left: 2.8em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-container label {
  margin-left: 5px; /* Adjust spacing between checkbox and label */
}

</style>
