<template>
  <FormContainer :show-progress-bar="false">
    <div class="welcome-container">
      <p class="welcome">
        Welcome
      </p>
      <p class="landing-info">
        This is the RAPID EczemaNet clinical trials landing page. <br> <br>
        If you have been given a link to a form, follow that link directly.
      </p>
      <!-- <div class="landing-info">
        <p>
          For testing purposes you can generate a new dummy form and fill it in by clicking the button below.
        </p>
        <button
          class="new-form-button"
          @click="goToNewForm"
        >
          Fill in New Form!
        </button>
      </div> -->
      <p class="more-info">
        <br>
        <!-- TODO: provide a proper contact here -->
        For any further queries contact <a href="mailto:rapideczematrials@nottingham.ac.uk">rapideczematrials@nottingham.ac.uk</a>.
      </p>
    </div>
  </FormContainer>
</template>

<script>
import FormContainer from './Layout/FormContainer.vue'

import api from '@/services/api';

export default {
  name: 'LandingPage',
  components: {
    FormContainer,
  },
  methods: {
    async goToNewForm() {
      const uuid = await api.getNewFormId();
      this.$router.push('/form/' + uuid);
    },
  },
}

</script>

<style scoped>

.welcome-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  align-self: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  justify-content: space-evenly;
}

.welcome, .landing-info {
  margin-bottom: 1em;
}

.welcome {
  font-size: 2.2em;
}

.landing-info {
  font-size: 1.2em;
  width: 80%;
}

.more-info {
  font-size: 0.8em;
  margin-top: 0.5em;
}


.new-form-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  width: 60%;
  max-width: 400px;
  margin: 1em 5px;
  padding: 10px 20px;
  border-radius: 7px;
  border: none;
  background-color: #064896;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


</style>