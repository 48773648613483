<template>
  <header class="header">
    <div class="header-title">
      <div
        class="header-icon"
        @click="goHome"
      >
        <!-- EczemaNet -->
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'HeaderBanner',
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
}

</script>

<style scoped>
.header {
  background-color: var(--banner-color);
  width: 100%;
  height: 50px;
  /* height: 10%; */
  padding: 70px 0;
  color: white;
}

.header-title {
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 1.5em;
  text-align: left;
  position: relative;
  top: -20%;
  font-weight: bold;
  margin-left: 5%;
}

.header-icon {
  display: inline-block;
  cursor: pointer;
}

</style>
