<template>
  <FormContainer
    :show-progress-bar="false"
  >
    <ErrorPage v-if="uuidStatus === 'error'" />
    <div
      v-if="dataLoaded"
      class="disabled-form"
    >
      <!-- <PoemForm
        :answers="poemData"
        :is-results-page="true"
        :score="poemScore"
      /> -->
      <!-- <ScoradForm
        :show-section-title="false"
        :is-results-page="true"
        :score="scoradScore"
        :answers="scoradData"
        :show-next="true"
      /> -->
      <!-- <ImageResultsPage
        class="enabled-form"
        :uuid="uuid"
        :answers="poemData"
        :regions="regions"
      />       -->
      <ScoradForm
        :show-section-title="false"
        :is-results-page="true"
        :answers="scoradData"
        :show-next="true"
      />      
      <ImageResultsPage
        class="enabled-form"
        :uuid="uuid"
        :answers="scoradData"
        :regions="regions"
      />
    </div>
  </FormContainer>
</template>


<script>
import FormContainer from '../Layout/FormContainer.vue'
// import PoemForm from '@/components/Questions/PoemForm.vue'
import ScoradForm from '@/components/Questions/ScoradForm.vue'
import ImageResultsPage from './ImageResultsPage.vue'
import ErrorPage from '../ErrorPage.vue'

import api from '@/services/api';

export default {
  name: 'ResultsPage',
  components: {
    FormContainer,
    // PoemForm,
    ScoradForm,
    ImageResultsPage,
    ErrorPage,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uuidStatus: null,
      scoradData: {},
      // poemData: {},
      dataLoaded: false,
    };
  },
  async created() {
    // override styles 
    this.setStyle();

    this.uuidStatus = await api.getUuidStatus(this.uuid);
    if (this.uuidStatus === "complete" || this.uuidStatus === "processing") {
      try {
        const formData = await api.getResults(this.uuid);
        // TODO: this data should already be encoded as json from backend
        // this.poemData = typeof formData.poem_form === 'string' ? JSON.parse(formData.poem_form) : formData.poem_form;
        this.scoradData = typeof formData.scorad_form === 'string' ? JSON.parse(formData.scorad_form) : formData.scorad_form;
        // // If score is null or undefined, set to NA
        // this.poemScore = formData.poem_test != null ? `${formData.poem_test}/28` : "NA";
        // this.scoradScore = formData.scorad_score != null ? `${formData.scorad_score}/103` : "NA";

        this.dataLoaded = true;
      } catch (error) {
        this.uuidStatus = "results-error"
      }
    } // TODO: else case
  },
  beforeUnmount() {
    // reset styles
    document.documentElement.classList.remove('results');
  },
  methods: {
    setStyle() {
      document.documentElement.classList.add('results');
    },
  },
}

</script>

<style>

.disabled-form {
  pointer-events: none;
}

.enabled-form {
  pointer-events: auto;
}

</style>