import { createRouter, createWebHashHistory } from 'vue-router'
import FormHandler from './components/FormHandler.vue'
import ResultsPage from './components/Results/ResultsPage.vue'
import LandingPage from './components/LandingPage.vue'

const routes = [
  { 
    path: '/form/:uuid',
    name: 'UuidRoute',
    component: FormHandler,
    props: true,
  },
  {
    path: '/results/:uuid',
    name: 'ResultsRoute',
    component: ResultsPage,
    props: true,
  },
  {
    path: '/',
    name: 'LandingRoute',
    component: LandingPage
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
