<template>
  <FormContainer
    v-bind="currentForm.containerProps"
    :show-progress-bar="false"
    v-on="currentForm.containerOn"
  >
    <template v-if="uuidStatus"> 
      <SubmittedPage v-if="uuidStatus === 'complete' || uuidStatus === 'processing'" />
      <ErrorPage v-else-if="uuidStatus === 'error'" />
      <component
        :is="currentForm.is"
        v-else-if="uuidStatus === 'pending'"
        :uuid="uuid"
        :answers="currentForm.answers"
        v-bind="currentForm.props"
        v-on="currentForm.on"
      />
    </template>
  </FormContainer>
</template>

<script>
import FormContainer from './Layout/FormContainer.vue'
// import PoemForm from './Questions/PoemForm.vue'
import ScoradForm from './Questions/ScoradForm.vue'
import ImageUploadPage from './Upload/ImageUploadPage.vue'
import SubmittedPage from './SubmittedPage.vue'
import ErrorPage from './ErrorPage.vue'
import SubmissionErrorPage from './SubmissionErrorPage.vue'

import api from '@/services/api';

export default {
  name: 'FormHandler',
  components: {
    FormContainer,
    // PoemForm,
    ScoradForm,
    ImageUploadPage,
    SubmittedPage,
    ErrorPage,
    SubmissionErrorPage,
  },
  props: {
    uuid: {
      type: String,
      required: true,
      // default: null,
    },
  },
  data() {
    return {
      uuidStatus: null,
      currentFormIndex: 0, // Initialize to 0 to avoid undefined index
      // currentFormIndex: 5,
      imagesDeleted: false,
      forms: [
        // {
        //   is: PoemForm,
        //   on: { 'update-answers': this.updateAnswers, 'all-questions-answered': this.updateAllQuestionsAnswered },
        //   props: { questionnaireName: "PoemForm" },
        //   // Container logic handles navigation
        //   containerOn: { next: this.goToNextPage },
        //   containerProps: { showNext: true, allQuestionsAnswered: false, scrollStart: 0, scrollEnd: 50 },
        //   answers: {},
        // },
        {
          is: ScoradForm,
          on: { 'update-answers': this.updateAnswers,
            'update-regions': this.updateRegions,
            'all-questions-answered': this.updateAllQuestionsAnswered,
            'genitals-selected': this.updateGenitalsSelected,
          },
          props: { questionnaireName: "ScoradForm" },
          containerOn: { next: this.goToNextPage, previous: this.goToPreviousPage },
          containerProps: { showPrevious: false, showNext: true, allQuestionsAnswered: false, scrollStart: 50, scrollEnd: 100 },
          answers: {},
        },
        {
          is: ImageUploadPage,
          // TODO: check if its all-questions-answered here or something else (make it emit from image upload page)
          on: { 'update-answers': this.updateAnswers, 'all-images-uploaded': this.updateAllQuestionsAnswered, 'images-deleted': this.handleImagesDeleted },
          props: { questionnaireName: "ImageUploadPage", regions: new Set(), genitalsSelected: false },
          containerOn: { next: this.submitForm, previous: this.goToPreviousPage },
          containerProps: { showPrevious: true, showNext: true, nextText: "Submit" },
          answers: {},
        },
        {
          is: SubmittedPage,
          props: { uuid: this.uuid },
          containerProps: { showPrevious: false, showNext: false },
        },
        {
          is: SubmissionErrorPage,
        },
        {
          is: ErrorPage,
        },
      ],
    };
  },
  computed: {
    currentForm() {
      return this.forms[this.currentFormIndex];
    },
    // showProgressBar() {
    //   return this.currentFormIndex < this.forms.length - 3;
    // },
  },
  async created() {
    this.uuidStatus = await api.getUuidStatus(this.uuid)
    if (this.uuidStatus === 'complete' || this.uuidStatus === 'processing') {
      this.currentFormIndex = 2;
    } else if (this.uuidStatus === 'error') {
      this.currentFormIndex = 5;
    }
    else {
      this.currentFormIndex = 0;
    }

    // TODO: remove this
    try {
      const formData = await api.getResults(this.uuid);

      // check if formData.message is a URL, e.g. it contains "http" or "www"
      if (formData.message.includes("http") || formData.message.includes("www")) {
        let url = formData.message;
        // Add http:// if the URL does not already contain a scheme
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url;
        }
        // window.open(url).focus();
        // window.location.href = url; // Redirect the current tab to the new location
      }
    } catch (error) {
      // continue without making any changes  
    }
  },
  methods: {
    async submitForm() {
      try {
        // const poemForm = this.forms[0].answers;
        // const scoradForm = this.forms[1].answers;
        // const images = this.forms[2].answers;

        const scoradForm = this.forms[0].answers;

        // if scorad form has -1 in areas set to emptyt list
        if (scoradForm.areas.includes(-1)) {
          scoradForm.areas = [];
        }

        // // set overview images in backend here
        // // TODO: check if this is the right/ efficient way to do this
        // Object.keys(images).forEach(async key => {
        //   const overviewImage = images[key].overviewImage;
        //   const response = await api.setOverview(this.uuid, key, overviewImage.id);
        // })

        await api.submitForm(
          { 
            // poem_form: poemForm,
            scorad_form: scoradForm,
          },
          this.uuid
        );
        
        this.goToNextPage();
      } catch (error) {
        this.goToSubmissionErrorPage();
      }

    },
    goToNextPage() {
      if (this.currentFormIndex < this.forms.length - 1) {
        this.currentFormIndex++;
        window.scroll(0, 0);
      }
    },
    async goToPreviousPage() {
      if (this.currentFormIndex === 1) { // Check if the current form index corresponds to the ImageUploadPage
        let imageUploadForm = this.$refs.imageUploadPage;
        if (imageUploadForm) {
          await imageUploadForm.deleteImages();
        }        
        // let imageUploadForm = this.forms.find(form => form.props.questionnaireName === "ImageUploadPage");
        // if (imageUploadForm) {
        //   // Access the component instance and call the deleteImages method
        //   await imageUploadForm.is.methods.deleteImages();
        // }          
      }      
      if (this.currentFormIndex > 0) {
        this.currentFormIndex--;
        window.scroll(0, 0);
      }
      // // Navigate to the previous page
      // this.$emit('previous-page');      
    },
    goToSubmissionErrorPage() {
      this.currentFormIndex = this.forms.length - 2;
      window.scroll(0, 0);
    },
    updateAnswers({ answers }) {
      this.currentForm.answers = answers;
    },
    updateRegions(regions) {
      let imageUploadForm = this.forms.find(form => form.props.questionnaireName === "ImageUploadPage");
      if (imageUploadForm) {
        imageUploadForm.props.regions = regions;
      }
    },
    updateAllQuestionsAnswered(value) {
      this.currentForm.containerProps.allQuestionsAnswered = value;
    },
    updateGenitalsSelected(value) {
      let imageUploadForm = this.forms.find(form => form.props.questionnaireName === "ImageUploadPage");
      if (imageUploadForm) {
        imageUploadForm.props.genitalsSelected = value;
      }
    },
    // handleImagesDeleted() {
    //   console.log('Images deleted event received');
    // },    
  },
}
</script>

