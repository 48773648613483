<template>
  <div class="question-container">
    <p
      class="question-text"
      v-html="questionText"
    />
    <p
      v-if="!isResultsPage"
      class="no-regions-text"
    >
      If there are no body parts with eczema, please click this button: 
      <button
        class="no-regions-button"
        :class="{ 'no-regions-active': noRegionsButton }"
        @click="toggleNoRegions"
      >
        No eczema
      </button>
    </p>
    <div
      :class="{
        'diagram-container': true,
        'column-container': expanded_view,
        'disabled-diagram': noRegionsButton,
      }"
    >
      <div
        v-if="noRegionsButton"
        class="overlay-text"
      >
        No eczema
      </div>
      <div
        :class="{
          'aspect-ratio-preserver': true,
          'aspect-ratio-preserver-expanded': expanded_view,
        }"
      >
        <div class="svg-container">
          <AdultDiagramFront
            class="human-diagram"
            :selected-regions="localSelectedRegions['front']"
            @update-regions="(regions) => selectRegion(regions, 'front')"
            @genitals-selected="genitalsSelected"
          />
        </div>
      </div>
      <div
        :class="{
          'aspect-ratio-preserver': true,
          'aspect-ratio-preserver-expanded': expanded_view,
        }"
      >
        <div class="svg-container">
          <AdultDiagramBack
            class="human-diagram"
            :selected-regions="localSelectedRegions['back']"
            @update-regions="(regions) => selectRegion(regions, 'back')"
          />
        </div>
      </div>
      <!-- Don't show expand button for results page -->
      <div
        v-if="!expanded_view && !isResultsPage"
        class="expand_icon"
        @click="expanded_view = true"
      >
        <img
          src="@/assets/expand_icon.svg"
          alt="Expand"
        >
      </div>
      <div
        v-if="expanded_view"
        class="expand_icon"
        @click="expanded_view = false"
      >
        <img
          src="@/assets/minimize_icon.svg"
          alt="Expand"
        >
      </div>
    </div>
  </div>
</template>

<script>
import AdultDiagramBack from "./AdultDiagramBack.vue";
import AdultDiagramFront from "./AdultDiagramFront.vue";

const NUM_FRONT_REGIONS = 23;
const NUM_BACK_REGIONS = 22;

// const idToRegion = new Map([
//   // these region names are linked to those accepted in the backend
//   // must be changed with the backend accordingly
//   // -- front diagram --
//   [0, "Head & Neck"], // scalp
//   [1, "Head & Neck"], // face
//   [2, "Head & Neck"], // neck
//   [3, "Head & Neck"], // right_shoulder
//   [4, "Upper Limbs"], // right_elbow
//   [5, "Upper Limbs"], // right_forearm
//   [6, "Upper Limbs"], // right_hand
//   [7, "Upper Limbs"], // left_shoulder
//   [8, "Upper Limbs"], // left_elbow
//   [9, "Upper Limbs"], // left_forearm
//   [10, "Upper Limbs"], // left_hand
//   [11, "Trunk"], // body_up
//   [12, "Trunk"], // body_down
//   [13, "Trunk"], // belly
//   [14, ""], // genitals
//   [15, "Lower Limbs"], // right_thigh
//   [16, "Lower Limbs"], // right_knee
//   [17, "Lower Limbs"], // right_calf
//   [18, "Lower Limbs"], // right_foot
//   [19, "Lower Limbs"], // left_thigh
//   [20, "Lower Limbs"], // left_knee
//   [21, "Lower Limbs"], // left_calf
//   [22, "Lower Limbs"], // left_foot
//   // -- back diagram --
//   [23, "Head & Neck"], // scalp
//   [24, "Head & Neck"], // head
//   [25, "Upper Limbs"], // right_shoulder
//   [26, "Upper Limbs"], // right_elbow
//   [27, "Upper Limbs"], // right_forearm
//   [28, "Upper Limbs"], // right_hand
//   [29, "Upper Limbs"], // left_shoulder
//   [30, "Upper Limbs"], // left_elbow
//   [31, "Upper Limbs"], // left_forearm
//   [32, "Upper Limbs"], // left_hand
//   [33, "Trunk"], // body_up
//   [34, "Trunk"], // body_down
//   [35, "Trunk"], // lower_back
//   [36, "Trunk"], // bum
//   [37, "Lower Limbs"], // right_thigh
//   [38, "Lower Limbs"], // right_knee
//   [39, "Lower Limbs"], // right_calf
//   [40, "Lower Limbs"], // right_foot
//   [41, "Lower Limbs"], // left_thigh
//   [42, "Lower Limbs"], // left_knee
//   [43, "Lower Limbs"], // left_calf
//   [44, "Lower Limbs"], // left_foot
// ]);

// const idToRegion = new Map([
//   // these region names are linked to those accepted in the backend
//   // must be changed with the backend accordingly
//   // -- front diagram --
//   [0, "Head & Neck"], // scalp
//   [1, "Head & Neck"], // face
//   [2, "Head & Neck"], // neck
//   [3, "Arms & Hands"], // right_shoulder
//   [4, "Arms & Hands"], // right_elbow
//   [5, "Arms & Hands"], // right_forearm
//   [6, "Arms & Hands"], // right_hand
//   [7, "Arms & Hands"], // left_shoulder
//   [8, "Arms & Hands"], // left_elbow
//   [9, "Arms & Hands"], // left_forearm
//   [10, "Arms & Hands"], // left_hand
//   [11, "Front & Back of Body"], // body_up
//   [12, "Front & Back of Body"], // body_down
//   [13, "Front & Back of Body"], // belly
//   [14, ""], // genitals
//   [15, "Legs & Feet"], // right_thigh
//   [16, "Legs & Feet"], // right_knee
//   [17, "Legs & Feet"], // right_calf
//   [18, "Legs & Feet"], // right_foot
//   [19, "Legs & Feet"], // left_thigh
//   [20, "Legs & Feet"], // left_knee
//   [21, "Legs & Feet"], // left_calf
//   [22, "Legs & Feet"], // left_foot
//   // -- back diagram --
//   [23, "Head & Neck"], // scalp
//   [24, "Head & Neck"], // head
//   [25, "Arms & Hands"], // right_shoulder
//   [26, "Arms & Hands"], // right_elbow
//   [27, "Arms & Hands"], // right_forearm
//   [28, "Arms & Hands"], // right_hand
//   [29, "Arms & Hands"], // left_shoulder
//   [30, "Arms & Hands"], // left_elbow
//   [31, "Arms & Hands"], // left_forearm
//   [32, "Arms & Hands"], // left_hand
//   [33, "Front & Back of Body"], // body_up
//   [34, "Front & Back of Body"], // body_down
//   [35, "Front & Back of Body"], // lower_back
//   [36, "Front & Back of Body"], // bum
//   [37, "Legs & Feet"], // right_thigh
//   [38, "Legs & Feet"], // right_knee
//   [39, "Legs & Feet"], // right_calf
//   [40, "Legs & Feet"], // right_foot
//   [41, "Legs & Feet"], // left_thigh
//   [42, "Legs & Feet"], // left_knee
//   [43, "Legs & Feet"], // left_calf
//   [44, "Legs & Feet"], // left_foot
// ]);

const idToRegion = new Map([
  // these region names are linked to those accepted in the backend
  // must be changed with the backend accordingly
  // -- front diagram --
  [0, "scalp front"], // scalp
  [1, "face"], // face
  [2, "neck"], // neck
  [3, "right shoulder front"], // right_shoulder
  [4, "right elbow front"], // right_elbow
  [5, "right forearm front"], // right_forearm
  [6, "right hand front"], // right_hand
  [7, "left shoulder front"], // left_shoulder
  [8, "left elbow front"], // left_elbow
  [9, "left forearm front"], // left_forearm
  [10, "left hand front"], // left_hand
  [11, "body up front"], // body_up
  [12, "body down front"], // body_down
  [13, "belly"], // belly
  [14, "genitals"], // genitals
  [15, "right thigh front"], // right_thigh
  [16, "right knee front"], // right_knee
  [17, "right calf front"], // right_calf
  [18, "right foot front"], // right_foot
  [19, "left thigh front"], // left_thigh
  [20, "left knee front"], // left_knee
  [21, "left calf front"], // left_calf
  [22, "left foot front"], // left_foot
  // -- back diagram --
  [23, "scalp back"], // scalp
  [24, "head"], // head
  [25, "right shoulder back"], // right_shoulder
  [26, "right elbow back"], // right_elbow
  [27, "right forearm back"], // right_forearm
  [28, "right hand back"], // right_hand
  [29, "left shoulder back"], // left_shoulder
  [30, "left elbow back"], // left_elbow
  [31, "left forearm back"], // left_forearm
  [32, "left hand back"], // left_hand
  [33, "body up back"], // body_up
  [34, "body down back"], // body_down
  [35, "lower back"], // lower_back
  [36, "buttocks"], // bum
  [37, "right thigh back"], // right_thigh
  [38, "right knee back"], // right_knee
  [39, "right calf back"], // right_calf
  [40, "right foot back"], // right_foot
  [41, "left thigh back"], // left_thigh
  [42, "left knee back"], // left_knee
  [43, "left calf back"], // left_calf
  [44, "left foot back"], // left_foot
]);

export default {
  name: "DiagramQuestion",
  components: { AdultDiagramFront, AdultDiagramBack },
  props: {
    questionId: {
      type: String,
      required: true,
    },
    questionText: {
      type: String,
      required: true,
    },
    selectedRegionKeys: {
      type: Array,
      default: () => [],
    },
    isResultsPage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update", "update-regions", "genitals-selected"],
  data() {
    return {
      noRegionsButton: this.selectedRegionKeys.includes(-1),
      localSelectedRegions: this.keysToRegions(this.selectedRegionKeys),
      savedSelectedRegions: this.keysToRegions([]),
      expanded_view: false,
    };
  },
  computed: {
    regionKeys() {
      return this.noRegionsButton
        ? [-1]
        : this.regionsToKeys(this.localSelectedRegions);
    },
    regionNames() {
      return this.selectedRegionNames(this.regionKeys);
    },
  },
  methods: {
    emitUpdate() {
      this.$emit("update", {
        questionId: this.questionId,
        selectedOption: this.regionKeys,
      });
      this.$emit("update-regions", this.regionNames);
    },
    keysToRegions(keys) {
      const regions = {
        front: new Array(NUM_FRONT_REGIONS).fill(false),
        back: new Array(NUM_BACK_REGIONS).fill(false),
      };

      if (keys.includes(-1)) {
        return regions;
      }
      keys.forEach((key) => {
        if (key < NUM_FRONT_REGIONS) {
          regions.front[key] = true;
        } else {
          regions.back[key - NUM_FRONT_REGIONS] = true;
        }
      });
      return regions;
    },
    regionsToKeys(regions) {
      const keys = [];
      regions.front.forEach((region, index) => {
        if (region) keys.push(index);
      });
      regions.back.forEach((region, index) => {
        if (region) keys.push(index + NUM_FRONT_REGIONS);
      });
      return keys;
    },
    toggleNoRegions() {
      // if user is clicking "No eczema"
      if (!this.noRegionsButton) {
        this.noRegionsButton = true;
        this.savedSelectedRegions = this.localSelectedRegions;
        this.localSelectedRegions = {};
      } else {
        this.noRegionsButton = false;
        this.localSelectedRegions = this.savedSelectedRegions;
      }
      this.emitUpdate();
    },
    selectRegion(regions, side) {
      this.localSelectedRegions[side] = regions;
      this.emitUpdate();
    },
    selectedRegionNames(regionKeys) {
      if (regionKeys.includes(-1)) {
        return new Set();
      } else {
        let selectedRegionNames = regionKeys.map((id) => idToRegion.get(id));
        // remove empty strings (genitals) (or if no regions button selected: -1)
        // (coded this way to avoid accidentally asking for genitals in the image upload section)
        selectedRegionNames = selectedRegionNames.filter((name) => name);
        return new Set(selectedRegionNames);
      }
    },
    genitalsSelected(value) {
      this.$emit("genitals-selected", value);
    },
  },
};
</script>

<style>
.question-container {
  width: 90%;
  padding-bottom: 3em;
  margin-left: 50px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.diagram-container {
  position: relative;
  box-sizing: border-box;
  /* padding: 1em; */
  border: 15px solid #e0e0e0;
  /* max-width: 1000px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto;  Center the container */
  margin-left: 1.5em;
}

.column-container {
  flex-direction: column;
}

.disabled-diagram {
  pointer-events: none; /* Make it non-interactable */
  opacity: 0.3; /* Make it appear "greyed out" */
}

.no-regions-text {
  /* font-size: 1.2em; */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  font-family: "Roboto", sans-serif;
}

.no-regions-button {
  border: 1px solid #686868;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 4px;
  border-radius: 8px;
  width: 200px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.no-regions-button:hover {
  background-color: var(--affected-region-hover-color);
}

.no-regions-button:active {
  background-color: var(--affected-region-color);
  color: white;
}

.no-regions-active {
  background-color: var(--affected-region-color);
  color: white;
}

.no-regions-active:hover {
  background-color: var(--affected-region-color);
  color: white;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 2em;
  color: #000;
  text-align: center;
  background-color: white;
}

.expand_icon {
  position: absolute;
  cursor: pointer;
  top: 2em;
  right: 2em;
  width: 2em;
  /* margin: 10px; */
}

.expand_icon img {
  width: 100%;
}

.expand_icon:hover {
  opacity: 0.7;
}

.aspect-ratio-preserver {
  position: relative;
  width: 40%;
  padding-top: 90%; /* Adjust the padding value for different aspect ratios */
}

.aspect-ratio-preserver-expanded {
  width: 100%;
  padding-top: 200%;
}

.svg-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Now the SVG will resize to fit the container, preserving aspect ratio */
}

.human-diagram {
  width: 100%;
  height: 100%;
}

.question-text {
  margin-bottom: 10px;
  font-size: 1em;
}

@media (max-width: 600px) {
  .question-container {
    margin-left: 0;
    width: 100%;
  }

  .diagram-container {
    margin-left: 0;
  }

  .no-regions-button {
    margin-top: 10px;
  }
}

/* styles for the children components DiagramFront and DiagramBack */

.affected-region {
  fill: var(--affected-region-color);
  stroke: var(--affected-region-stroke);
  stroke-width: 1px;
  cursor: pointer;
}

.affected-region:hover {
  fill: var(--affected-region-hover-color);
}

.non-affected-region {
  fill: var(--non-affected-region-color);
  stroke: var(--non-affected-region-stroke);
  stroke-width: 1px;
  cursor: pointer;
}

.non-affected-region:hover {
  fill: var(--affected-region-hover-color);
}

/* This will only apply when the device does not support hovering (like most touchscreens) */
@media (hover: none) {
  .affected-region:hover {
    fill: var(--affected-region-color);
  }
  .non-affected-region:hover {
    fill: var(--non-affected-region-color);
  }
}
</style>
