<template>
  <QuestionnaireBase
    :questionnaire-title="title"
    :content="content"
    :show-section-title="showSectionTitle"
    :is-results-page="isResultsPage"
    :score="score"
  />
</template>
  
  <script>
  import QuestionnaireBase from './QuestionnaireBase.vue';

  // note the id's link with the keys expected by the backend. So must be kept the same.
  
  export default {
    components: { QuestionnaireBase },
    props: {
      showSectionTitle: {
        type: Boolean,
        required: false,
        default: true,
      },
      isResultsPage: {
        type: Boolean,
        required: false,
        default: false,
      },
      score: {
        type: String,
        required: false,
        default: 'NA',
      },
    },
    data() {
      return {
        // title: '2. PO-SCORAD', // (Patient Oriented SCORing Atopic Dermatitis) 
        title: '', // (Patient Oriented SCORing Atopic Dermatitis) 
        // showHelpText: false,
        content: [
          {
            // section: 'Section 1: Extension of Disease',
            section: 'Select body area',
            questions: [
              {
                id: "areas",
                type: "diagram",
                text:
                  // '<span style="font-weight: bold;">1. </span> Select all the regions which contain Eczema. <span style="color: grey; font-style: italic;">Do so by pressing the corresponding regions on the diagram.</span>',
                  ''
              },
            ]
          },
        //   {
        //     section: 'Section 2: Severity of Disease',
        //     questions: [
        //       {
        //         id: "dryness",
        //         type: "multipleChoice",
        //         text:
        //           '<span style="font-weight: bold;">1. </span> How <span style="font-weight: bold;">dry</span> is the affected skin? <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
        //         options: ["Not at all (0)", "Mildly (1)", "Significantly (2)", "Extremely (3)"],
        //       },
        //       {
        //         id: "redness",
        //         type: "multipleChoice",
        //         text:
        //         '<span style="font-weight: bold;">2. </span> How <span style="font-weight: bold;">red</span> is the affected skin? <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
        //         options: ["Not at all (0)", "Mildly (1)", "Significantly (2)", "Extremely (3)"],
        //       },
        //       {
        //         id: "swelling",
        //         type: "multipleChoice",
        //         text:
        //         '<span style="font-weight: bold;">3. </span> How <span style="font-weight: bold;">swollen</span> is the affected skin? <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
        //         options: ["Not at all (0)", "Mildly (1)", "Significantly (2)", "Extremely (3)"],
        //       },
        //       {
        //         id: "crusting",
        //         type: "multipleChoice",
        //         text:
        //         '<span style="font-weight: bold;">4. </span> How prevelant is <span style="font-weight: bold;">oozing or crusting</span> in the affected skin? <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
        //         options: ["Not at all (0)", "Mildly (1)", "Significantly (2)", "Extremely (3)"],
        //       },
        //       {
        //         id: "scratching",
        //         type: "multipleChoice",
        //         text:
        //         '<span style="font-weight: bold;">5. </span> How <span style="font-weight: bold;">scratched</span> is the affected skin? <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
        //         options: ["Not at all (0)", "Mildly (1)", "Significantly (2)", "Extremely (3)"],
        //       },
        //       {
        //         id: "thickening",
        //         type: "multipleChoice",
        //         text:
        //         '<span style="font-weight: bold;">6. </span> How <span style="font-weight: bold;">thick</span> is the affected skin? <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
        //         options: ["Not at all (0)", "Mildly (1)", "Significantly (2)", "Extremely (3)"],
        //       },
        //     ]
        //   },
        //   {
        //     section: 'Section 3: Subjective Symptoms',
        //     questions: [ 
        //       {
        //         id: "itch",
        //         type: "multipleChoice",
        //         text:
        //           '<span style="font-weight: bold;">1. </span> How  much did eczema bother you with <span style="font-weight: bold;">itching</span>? <span style="color: grey; font-style: italic;">Please choose one of the boxes below. (On a scale of 0 to 10)</span>',
        //         options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        //       },
        //       {
        //         id: "sleep",
        //         type: "multipleChoice",
        //         text:
        //           '<span style="font-weight: bold;">2. </span> How  much did eczema stop you from <span style="font-weight: bold;">sleeping well</span>? <span style="color: grey; font-style: italic;">Please choose one of the boxes below. (On a scale of 0 to 10)</span>',
        //           options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        //       }
        //     ]
        //   }
        ],
      };
    },
  };
</script>
