<template>
  <div class="submit-container">
    <p class="thanks">
      An error occured. <br> This link may be invalid or has expired.
    </p>
    <p class="more-info">
      For any further queries contact <a href="mailto:rapideczematrials@nottingham.ac.uk">rapideczematrials@nottingham.ac.uk</a>.
    </p>
  </div>
</template>

<style scoped>

.submit-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  align-self: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.thanks {
  font-size: 2em;
  margin-bottom: 0em;
}

.more-info {
  font-size: 0.8em;
  margin-top: 0.5em;
}

</style>